<template>
  <div>
    <el-select
      v-if="modelVlaue == 'id'"
      v-model="id"
      filterable
      :size="size"
      :placeholder="placeTitle"
      remote
      :remote-method="remoteMethod"
      :clearable="clearable"
      :disabled="disabled"
      :loading="loading"
      :trigger-on-focus="false"
         :style="{ width: width }"
    >
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="'['+ item.code + '] ' + item.name_en "
        :value="String(item.id)">
      </el-option>
    </el-select>
    <el-select
      v-else
      v-model="code"
      filterable
      :size="size"
      :placeholder="placeTitle"
      remote
      :remote-method="remoteMethod"
      :clearable="clearable"
      :disabled="disabled"
      :loading="loading"
      :trigger-on-focus="false"
      :style="{ width: width }"
    >
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="'['+ item.code + '] ' + item.name_en "
        :value="item.code">
      </el-option>
    </el-select>
  </div>
</template>


<script>
export default {
name: "shipCompanySearch",
  props: {
     width: {
      type: String,
      default: "",
    },
    placeTitle: {
      type: String,
      default: "",
    },
    value: {
      type: String | Number,
      default: null,
    },
    size: {
      type: String,
      default: "small",
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    modelVlaue: {
      type: String,
      default: 'id'
    }
  },
  data() {
    return {
      id: null,
      code: null,
      options: [],
      loading: false
    }
  },
  watch: {
    id(nv) {
      if (this.modelVlaue == 'id') {
        this.$emit("input", nv);
      }

    },
    code(nv) {
      if (this.modelVlaue != 'id') {
        this.$emit("input", nv);
      }
    },
    value(nv) {
      if (this.modelVlaue == 'id') {
        this.id = nv;
      } else {
        this.code = nv;
      }
    },
  },
  mounted() {
      this.$nextTick(() => {
        if (this.modelVlaue === 'id') {
          this.id = this.value
        } else {
          this.code = this.value
        }
      })
  },
  methods: {
    async remoteMethod(queryString) {
      this.loading = true
      let arr = await this.queryCompany(queryString)
      this.options = arr
    },
    async queryCompany(queryString) {
      try {
        let parmas = {
          name: queryString,
        };
        let data = await this.$store.dispatch('API_ymp_company/getFreightCompanyList', parmas);
        this.loading = false
        if (data.success) {
          let arr = []
          if (data.data.length > 0) {
            data.data.forEach(item => {
              item.info.forEach(it => {
                arr.push(it)
              })
            })
          }
          arr.forEach((ee) => {
            ee.value = ee.name_en
          });
         return arr
        }
      } catch {}
    },
  },
}
</script>

<style scoped>

</style>
