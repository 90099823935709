<template>
    <el-dialog :title="pageTitle" :visible.sync="dialogFormVisible" width="600px">
        <div>
            <el-form :model="form" :label-width="'100px'" :rules="rules" ref="baseform">
                <el-form-item label="费用名称" prop="cost_name">
                    <el-select
                        style="width:100%"
                        size="small"
                        :disabled="feeNamaDis"
                        v-model="form.cost_name"
                        filterable
                        remote
                        placeholder="请输入费用名称"
                        :remote-method="remoteMethod"
                        @change="handleChange"
                        :loading="loading">
                        <el-option
                            v-for="(item) in options"
                            :key="item.id"
                            :label="item.name_zh"
                            :value="item.name_zh">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="国内/国外" >
                    <el-select v-model="form.oversea" size="small">
                        <el-option label="国内" value="1"></el-option>
                        <el-option label="国外" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="计量单位" prop="measure_unit">
                    <el-select  v-model="form.measure_unit" size='small' placeholder="请选择" >
                        <el-option
                        v-for="(it,i) in measureUnitList"
                        :key="i"
                        :label="it.name_zh + '[' + it.code + ']'"
                        :value="it.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="币种" prop="currency">
                    <el-select  v-model="form.currency" size='small' filterable placeholder="请选择" >
                        <el-option
                        v-for="(it,i) in codeArr"
                        :key="i"
                        :label="it.code"
                        :value="it.code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="数量"  size="small" prop="quantity">
                    <el-input v-model="form.quantity" autocomplete="off" oninput="value=value.replace(/^\D*([1-9]\d*\.?\d{0,2})?.*$/, '$1')" maxlength="7"></el-input>
                </el-form-item>
                <el-form-item label="应收单价"  size="small" prop="unit_price">
                    <el-input v-model="form.unit_price" autocomplete="off" oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1')" maxlength="9"></el-input>
                </el-form-item>
                <el-form-item label="应收金额"  size="small" prop="price">
                    <el-input v-model="form.price" :disabled="true" placeholder="根据单价自动计算" autocomplete="off" oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1')"></el-input>
                </el-form-item>
                <el-form-item label="应付单价"  size="small" prop="unit_amount" v-if="!(spell_type == 2)">
                    <el-input v-model="form.unit_amount" autocomplete="off" oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1')" maxlength="9"></el-input>
                </el-form-item>
                <el-form-item label="应付金额"  size="small" prop="cost_amount" v-if="!(spell_type == 2)">
                    <el-input v-model="form.cost_amount" :disabled="true" placeholder="根据单价自动计算" autocomplete="off" oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1')"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false" size='small'>取 消</el-button>
            <el-button type="primary" @click="handle_add" size='small'>确 定</el-button>
        </div>
</el-dialog>
</template>
<script>
export default {
    props:{
		spell_type:{
			type: Number| String,
			default:0
		},
	},
    data() {
        return{
            prop_index:null,
            dialogFormVisible: false,
            pageTitle: '新增费用',
            feeNamaDis: false,
            form: {
                cost_name: null,
                oversea: "1",
                measure_unit: "",
                currency: "",
                quantity: 1, // 数量
                unit_price:'', // 应收单价
                unit_amount:'', // 应付单价
                price: "",
                cost_amount:''
            },
            rules: {
                cost_name: [{ required: true, message: " ", trigger: "blur" }],
                currency: [{ required: true, message: " ", trigger: "change" }],
                quantity: [{ required: true, message: " ", trigger: "blur" }],
                unit_price: [{ required: true, message: " ", trigger: "blur" }],
                unit_amount: [{ required: true, message: " ", trigger: "blur" }],
                measure_unit: [{ required: true, message: " ", trigger: "change" }],
                price: [{ required: true, message: " ", trigger: "change" }],
                cost_amount:[{ required: true, message: " ", trigger: "change" }],
            },
            codeArr:[],
            measureUnitList:[], // 计费单位列表
            options:[],
            loading:false
        }
    },
    watch:{
        "form.quantity":function(value) {
            this.subTotal('quantity',value)
        },
        "form.unit_price":function(value) {
            this.subTotal('unit_price',value)
        },
        "form.unit_amount":function(value) {
            this.subTotal('unit_amount',value)
        },
    },
    mounted(){
        this.getCodeList();
    },
    methods:{
        subTotal(type,val){
            if(type == 'quantity'){
                if(this.form.unit_price){
                    this.form.price = (1*this.form.unit_price*val).toFixed(2)
                }else this.form.price = '';
                if(this.form.unit_amount){
                    this.form.cost_amount = (1*this.form.unit_amount*val).toFixed(2)
                }else this.form.cost_amount = '';
            }else if(type == 'unit_price'){
                if(this.form.quantity){
                    this.form.price = (1*this.form.unit_price*this.form.quantity).toFixed(2)
                }else this.form.price = '';
            }else if(type == 'unit_amount'){
                if(this.form.quantity){
                    this.form.cost_amount = (1*this.form.unit_amount*this.form.quantity).toFixed(2)
                }else this.form.cost_amount = '';
            }
        },
        async getCodeList(){
            let res = await this.$store.dispatch("baseConsole/getcurrencyList",{
                start:0,
                limit:300
            })
            this.codeArr=res.data
            let measureUnitRes = await this.$store.dispatch("baseConsole/getMeasureUnitList")
            this.measureUnitList = measureUnitRes.data;
        },
        openDialog(index, row){
            this.dialogFormVisible=true
            this.prop_index=index;
            
            if(row){
                this.pageTitle = '编辑费用';
                if(this.spell_type == 2){ // 拼箱编辑禁用 费用名称
                    this.feeNamaDis = true; 
                }else{
                    this.feeNamaDis = false; 
                }
                this.form = {
                    cost_name: row.cost_name,
                    oversea: row.oversea,
                    measure_unit: row.measure_unit,
                    currency: row.currency,
                    quantity: row.quantity, // 数量
                    unit_price: row.unit_price, // 应收单价
                    unit_amount: row.unit_amount, // 应收单价
                    price: row.price,
                    cost_amount: row.cost_amount
                };
            }else{
                this.pageTitle = '新增费用';
                this.feeNamaDis = false; 
                this.$nextTick(()=>{
                    this.form = {
                        cost_name: null,
                        oversea: "1",
                        measure_unit: "",
                        currency: "",
                        quantity: 1, // 数量
                        unit_price:'', // 应收单价
                        unit_amount: '',
                        price: "",
                        cost_amount:''
                    };
                    this.options=[];
                    this.$refs["baseform"].resetFields();
                })
            }
        },
        handle_add(){
            let that = this;
            this.$refs["baseform"].validate((valid) => {
                if (valid) {
                    if(parseFloat(that.form.price) + parseFloat(that.form.cost_amount) <= 0){
                        this.$message.warning("请填写正确的金额");
                        return false;
                    }
                    let obj = JSON.parse(JSON.stringify(that.form));
                    let measure_unit_obj = this.measureUnitList.find((i)=>{
                        return i.id == obj.measure_unit;
                    });
                    obj.measure_name_zh = measure_unit_obj.name_zh;
                    obj.measure_code = measure_unit_obj.code;
                    this.$emit('ret_cost',{index:this.prop_index,obj})
                    this.dialogFormVisible=false
                }
            });
        },
        handleChange(val) {
            let row = this.options.find(item => val == item.name_zh)
            this.form.currency = row.currency;
            this.form.measure_unit = row.measure_unit;
            this.form.unit_price = row.unit_price;
        },
        async remoteMethod(query) {
            if (query !== '') {
                this.loading = true;
                let res=await this.$store.dispatch('baseConsole/get_fee',{
                    name:query,
                    nopage:1,
                });
                this.options=res.data
                this.loading = false
            } else {
                this.options = [];
            }
        },
    }
}
</script>
<style lang="less" scoped>
    .tag_box{
        display: flex;
        flex-wrap: wrap;
    }
    .myTag{
        margin-right:15px;
        margin-bottom:15px ;
    }
    .cost_name{
        height: 40px;
        width: 100;
        border-bottom: 1px solid #DCDFE6;
        cursor: pointer;
    }
    /deep/ .el-dialog__body{
        padding-bottom: 0;
    }
</style>