<template>
	<el-dialog title="选择费用组" :visible.sync="dialogFormVisible" width="1000px">
		<div class="search">
      <div class="searchFl" v-if="pageData">
        <el-input class="searchInput" clearable v-model="searchQuery.quotation_no" size="small" placeholder="请输入报价编号"></el-input>
				<shipCompany
					:placeTitle="'请输入船公司'"
					:width="'200px'"
					v-model="searchQuery.shipping_company"
				></shipCompany>
				<span style="margin-left:10px">起运港：{{pageData.from_port_name}}</span>
				<span style="margin-left:10px">目的港：{{pageData.dest_port_name}}</span>
      </div>
      <div>
        <el-button size="small" type="primary" @click="searchChange"
          >查询</el-button
        >
      </div>
    </div>
		<div class="tableBlock">
			<el-table 
				:height="100"
				v-tableHeight="{ bottomOffset: 74 }"
				:data="costList" :tableLoading="loading" style="width: 100%; margin-top:20px; font-size:13px" max-height="600" border>
				<el-table-column label="报价编号" :show-overflow-tooltip="true">
					<template slot-scope="scope">{{ scope.row.quotation_no | textFormat }}</template>
				</el-table-column>
				<el-table-column label="报价日期" :show-overflow-tooltip="true">
					<template slot-scope="scope">{{ scope.row.created_time | secondFormat('LL') }}</template>
				</el-table-column>
				<el-table-column label="海运费" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						<template v-for="item in scope.row.sea_freight"> 
							{{ item.code + ' ' + item.price + '/' + item.name | textFormat }};
						</template>
					</template>
				</el-table-column>
				<el-table-column label="货物类型" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						{{ scope.row.is_dangerous == 1? ' 危险品':'普货' | textFormat }}
					</template>
				</el-table-column>
				<el-table-column label="UNNO" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						<span class="amount">{{ scope.row.un_no | textFormat }}</span>
					</template>
				</el-table-column>
				<el-table-column label="有效期" :show-overflow-tooltip="true" width="200">
					<template slot-scope="scope">
						<span class="amount">{{ scope.row.validity_start | secondFormat('LL') }}</span> 至 {{ scope.row.validity_end | secondFormat('LL') }}
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="120">
					<template slot-scope="scope">
						<el-button @click.native.prevent="getCostList(scope.row.id)" type="text" >
						 添加
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="paginationBlock">
      <el-pagination
        @current-change="handleChange"
        :page-size="15"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
	</el-dialog>
</template>
<script>
import shipCompany from "~ymp/components/common/shipCompany"
export default {
	components:{ shipCompany },
	props:{
		user_id:{
			type: Number| String,
			default:0
		},
	},
	data() {
		return {
			dialogFormVisible: false,
			codeArr: [],
			costList: [],
			currentPage: 1,
			total: 0,
			searchQuery:{
        quotation_no:"",
        shipping_company:'',
      },
			loading: false,
			pageData: {}, //页面传入参数
			container_info: "" //箱型信息
		}
	},
	watch: {

	},
	mounted() {

	},
	methods: {
		openDialog(pageData, container_info) {
			this.dialogFormVisible = true;
			this.pageData = pageData || {};
			this.container_info = container_info || '';
			this.getList();
			this.$nextTick(() => {
				
			})
		},
		async getCostList(id){
			if(!id){
				return false;
			}
			// 整箱报价要加危险品信息,以便后台计算费用报价
			let unnos = [];
			try{
				let newArr = this.pageData.special_goods.filter(item=>{
					return item.un_no != '' || item.un_no != undefined
				})
				newArr.forEach((item)=>{
					unnos.push(item.un_no)
				})
			}catch{}
      let res = await this.$store.dispatch("API_bookingSpace/getEntrustFeeList",{
          entrust_kind: 1 ,
          quotation_id: id,
					container_info: this.container_info,
					cargo_type: this.pageData.cargo_type,
					un_no: unnos.join(',')
      })
      let list = [],
      obj = {};
      // res.data.filter((it)=>{
      //   if(!obj.hasOwnProperty(it.fee_name)){
      //     obj[it.fee_name] = it.fee_name;
      //     list.push(it)
      //   }else{
      //     return;
      //   }
      // })
			if(res.success && res.data){
				list = res.data;
			}
			this.$emit('addCostList', list)
			this.dialogFormVisible = false;
    },
		searchChange() {
      this.costList = [];
      this.currentPage = 1;
      this.getList();
    },
		async getList() {
      this.loading = true;
      let params = {
        limit: 15,
        start: this.currentPage - 1,
        sales_id: this.USER_INFO.id,
				from_port: this.pageData.from_port,
				dest_port: this.pageData.dest_port,
        user_id: this.user_id,
        nopage:0,
      };
      params = Object.assign(params, this.searchQuery)
      let result = await this.$store.dispatch(
        "API_bookingSpace/getSellToClientQuotation",
        params
      );
      this.loading = false;
      if (result && result.success) {
				result.data.forEach(element => {
					try {
						element.sea_freight = JSON.parse(element.sea_freight)
					} catch (error) {
						element.sea_freight = []
					}
				});
        this.costList = result.count ? result.data : [];
        this.total = result.count;
      }
    },
		handleChange(e) {
      this.currentPage = e;
      this.getList();
    },
	}
}
</script>
<style lang="less" scoped>
.search {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  justify-content: space-between;

  .searchFl {
    width: 90%;
    display: flex;
    align-items: center;

    .searchInput {
      width: 200px;
      padding: 0 6px;
    }
  }
}

/deep/ .el-dialog__body {
	padding-bottom: 60px;
}
</style>